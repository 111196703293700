<template>
    <v-container fluid>
        <v-row>
            <v-col 
                style="display: flex; align-content: center; align-items: center;"
                cols="6"
                lg="6"
                md="6"
                sm="6"
            >
                <h3>Upload Documents - {{ serviceProjectResponse.idFormatted }}</h3>
            </v-col>
            <v-col 
                cols="6"
                style="text-align: right;"
            >
                <v-chip
                    color="var(--color__main)"
                    outlined
                    dark
                >
                    {{ serviceProjectResponse.statusDescription }}
                </v-chip>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <hr />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                cols="12"
            >                    
                <JobInformation 
                    :id="serviceProjectResponse.idService"
                    :project="projectSelected"
                    :showProjects="false"
                    :showJobSummary="false"
                    :showButtonsCreateEditProject="false"
                    :showButtonsCreateEditVersion="false"
                    :showClassification="true"
                    :showDisabledClassification="true"
                />
            </v-col>
        </v-row>

        <v-expansion-panels popout>

            <!-- PERMIT CARD -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-card-account-details-outline
                                </v-icon>
                                Permit Card
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Permit Card</label>
                                <v-file-input
                                    v-model="permitCardListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(permitCardListFiles, permitCardFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in permitCardFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(permitCardFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- PLANS APPROVED -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-check-decagram-outline
                                </v-icon>
                                Plans Approved
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Plans Approved</label>
                                <v-file-input
                                    v-model="plansApprovedListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(plansApprovedListFiles, plansApprovedFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in plansApprovedFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(plansApprovedFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- RECORDED NOC -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-check-decagram-outline
                                </v-icon>
                                Recorded Noc
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Recorded Noc</label>
                                <v-file-input
                                    v-model="recordedNocListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(recordedNocListFiles, recordedNocFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in recordedNocFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(recordedNocFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- SIGNED AND SEALED PLANS WITH CITY APPROVAL -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-check-decagram-outline
                                </v-icon>
                                Signed and Sealed Plans with city approval
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Signed and Sealed Plans with city approval</label>
                                <v-file-input
                                    v-model="signedAndSealedPlansListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(signedAndSealedPlansListFiles, signedAndSealedPlansFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in signedAndSealedPlansFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(signedAndSealedPlansFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- APPROVED PERMIT -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-check-decagram-outline
                                </v-icon>
                                Approved Permit
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Approved Permit</label>
                                <v-file-input
                                    v-model="approvedPermitListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(approvedPermitListFiles, approvedPermitFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in approvedPermitFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(approvedPermitFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- SUPPORTING DOCUMENTS -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-check-decagram-outline
                                </v-icon>
                                Supporting Documents (Optional)
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <canvas id="canvas" hidden></canvas>
                                <label>Supporting Documents (Optional)</label>
                                <v-file-input
                                    v-model="supportingDocumentsListFiles" 
                                    accept=".pdf"
                                    small-chips
                                    multiple
                                    outlined
                                    placeholder="Click here to add"
                                    prepend-inner-icon="mdi-paperclip"
                                    prepend-icon=""
                                    @change="addFilesToList(supportingDocumentsListFiles, supportingDocumentsFiles)"
                                    dense
                                ></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                v-for="(itemFile, index) in supportingDocumentsFiles.filter(item => item.deleted == false)"
                                :key="index"
                                cols="12"
                                lg="1"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="itemFile.id != 0"
                                    max-width="200"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, itemFile.id, itemFile.fileType, itemFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ itemFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ itemFile.fileName }}</span>
                                    <v-icon class="deletePhotoIcon" small right color="var(--color__red)" @click="confirmFileDelete(supportingDocumentsFiles, itemFile)">mdi mdi-delete</v-icon>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

            <!-- CHECK DOCUMENTS -->
            <v-col
                style="padding-top: 10px !important;"
                cols="12"
                lg="12"
                md="12"
                sm="12"
            >
                <v-expansion-panel>
                    <v-expansion-panel-header>
                        <v-row>
                            <v-col
                                cols="12"
                            >
                                <v-icon color="primary" left>
                                    mdi-check-all
                                </v-icon>
                                Check Documents
                            </v-col>
                        </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>

                        <v-row>
                            <v-col
                                cols="12"
                                lg="2"
                                md="4"
                                sm="12"
                                style="margin-bottom: 10px;"
                            >
                                <label>Specs</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                lg="2"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    v-if="specsFile.id != 0"
                                    max-width="300"
                                    style="text-align: center;"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, specsFile.id, specsFile.fileType, specsFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ specsFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ specsFile.fileName }}</span>
                                </v-card>
                            </v-col>
                            <v-col
                                style="display: flex; align-content: center; align-items: center;"
                                cols="12"
                                lg="3"
                                md="4"
                                sm="12"
                            >
                                <v-btn-toggle
                                    v-model="specsStatus"
                                >
                                    <v-btn 
                                        @click="specsStatus = 0" 
                                        class="btnToogleDenied"
                                    >
                                        <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                    </v-btn>

                                    <v-btn 
                                        @click="specsStatus = 1"
                                        class="btnToogleApproved"
                                    >
                                        <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                    </v-btn>

                                </v-btn-toggle>
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col
                                cols="12"
                                lg="2"
                                md="4"
                                sm="12"
                                style="margin-bottom: 10px; margin-top: 40px;"
                            >
                                <label>Survey</label>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                cols="12"
                                lg="2"
                                md="4"
                                sm="12"
                            >
                                <v-card 
                                    max-width="300"
                                    style="text-align: center;"
                                >
                                    <v-card
                                        class="cardDownload"
                                        @click="downloadFile(URL_ATTACHMENT, surveyFile.id, surveyFile.fileType, surveyFile.fileName)"
                                    >
                                        <v-card-text>
                                            <v-icon x-large>mdi mdi-file-download-outline</v-icon>
                                            <span class="imageFileName">{{ surveyFile.fileName }}</span>
                                        </v-card-text>
                                    </v-card>
                                    <span class="imageFileName">{{ surveyFile.fileName }}</span>
                                </v-card>
                            </v-col>
                            <v-col
                                style="display: flex; align-content: center; align-items: center;"
                                cols="12"
                                lg="3"
                                md="4"
                                sm="12"
                            >
                                <v-btn-toggle
                                    v-model="surveyStatus"
                                >
                                    <v-btn 
                                        @click="surveyStatus = 0" 
                                        class="btnToogleDenied"
                                    >
                                        <v-icon left class="btnToogleDenied">mdi-thumb-down-outline</v-icon>Denied
                                    </v-btn>

                                    <v-btn 
                                        @click="surveyStatus = 1"
                                        class="btnToogleApproved"
                                    >
                                        <v-icon left class="btnToogleApproved">mdi-thumb-up-outline</v-icon>Approved
                                    </v-btn>

                                </v-btn-toggle>
                            </v-col>
                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-col>

        </v-expansion-panels>

        <div 
            class="text-center"
        >
            <v-row
                style="padding-top: 50px"
            >
                <v-col 
                    style="text-align: right"
                    cols="6"
                    lg="5"
                    md="4"
                    sm="6"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn 
                                class="mx-2"
                                v-on="on"
                                @click="cancel"
                                style="background-color: var(--color__red) !important; color: #ffffff !important;"
                            >
                                Cancel
                            </v-btn>
                        </template>
                        <span>Cancel</span>
                    </v-tooltip>
                </v-col>
                <v-col 
                    style="text-align: center"
                    cols="12"
                    lg="2"
                    md="4"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                v-on="on"
                                style="background-color: var(--color__silver) !important; color: #ffffff !important"
                                @click="save"
                            >
                                Save
                            </v-btn>
                        </template>
                        <span>Save and continue later</span>
                    </v-tooltip>
                </v-col>
                
                <v-col 
                    style="text-align: left"
                    cols="12"
                    lg="5"
                    md="4"
                    sm="12"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                class="mx-2"
                                v-on="on"
                                @click="validateAndFinish"
                            >
                                Finish
                            </v-btn>
                        </template>
                        <span>Finish all permitting process</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </div>

        <ActionDialog 
            v-on:methodConfirmToCall="dialog.methodConfirm"
            :showDialog.sync="dialog.show"
            :headerTitle="dialog.headerText"
            :bodyText="dialog.bodyText"
            :params="dialog.params"
        />

    </v-container>
</template>

<script>
    import Helpers from '@/utilities/Helpers';
    import ActionDialog from "@/components/Layout/ActionDialog";
    import JobInformation from "@/components/Services/JobInformation";
    import { 
        TYPE_DOCUMENT, 
        TYPE_ATTACHMENT, 
        URL_ATTACHMENT, 
        PROJECT_STATUS
    } from "@/utilities/Enums";

    export default ({

        components: {
            ActionDialog,
            JobInformation
        },

        mixins: [Helpers],

        data: () => ({

            URL_ATTACHMENT,

			serviceProjectResponse: {
                id: 0,
                userNameRegister: "",
                customerSelected: {
                    name: ""
                },
                addressSelected: {
                    address: ""
                },
            },

            projectSelected: null,

            permitCardListFiles: [],
            permitCardFiles: [],

            plansApprovedListFiles: [],
            plansApprovedFiles: [],

            recordedNocListFiles: [],
            recordedNocFiles: [],

            signedAndSealedPlansListFiles: [],
            signedAndSealedPlansFiles: [],

            approvedPermitListFiles: [],
            approvedPermitFiles: [],

            supportingDocumentsListFiles: [],
            supportingDocumentsFiles: [],

            surveyFile: { 
                id: 0,
                fileName: '', 
                fileType: 'application/pdf' 
            },
            surveyStatus: null,

            specsFile: { 
                id: 0,
                fileName: '', 
                fileType: 'application/pdf' 
            },
            specsStatus: null,
            
            dialog: {
                show: false,
                headerText: "",
                bodyText: "",
                methodConfirm: ( () => {} ),
                params: null
            },

            PROJECT_STATUS

        }),

        props: {
            id: {
                default: 0
            }
        },

        methods: {

            addFilesToList(listFiles, files) {

                if (listFiles) {

                    listFiles.forEach(itemFile => {

						if (itemFile) {

                            let fileName = itemFile.name;

                            if (itemFile.name && itemFile.name.length > 15) {
                                fileName = itemFile.name.toString().substring(0, 12) + "..."
                            }

                            let item = {
                                id: 0,
                                file: itemFile,
                                fileName: fileName,
                                deleted: false,
                            }

                            files.push(item);
						}	
					});
                }
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("serviceModule/GetServiceProjectById", this.id);

                    this.serviceProjectResponse = response.result;

                    this.projectSelected = {
                        id: this.serviceProjectResponse.id,
                        idService: this.serviceProjectResponse.idService,
                        classification: this.serviceProjectResponse.classification,
                        name: this.serviceProjectResponse.name,
                        step: this.serviceProjectResponse.step,
                        version: this.serviceProjectResponse.version,
                        versionName: this.serviceProjectResponse.versionName,
                        totalProject: this.serviceProjectResponse.totalProject
                    }

                    this.permitCardListFiles = [];
                    this.permitCardFiles = [];

                    this.plansApprovedListFiles = [];
                    this.plansApprovedFiles = [];
                    
                    let listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PERMITTING.value, idKey: this.id }); 

                    const permitCardListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERMITTING_PERMIT_CARD.value);
                    if (permitCardListFiles != null && permitCardListFiles != undefined) {

                        permitCardListFiles.forEach(itemFile => {

                            this.permitCardFiles.push({
                                id: itemFile.id,
                                file: new File([], itemFile.fileName),
                                fileName: itemFile.fileNameShort,
                                fileType: itemFile.fileType,
                                deleted: false,
                            });
                        })
                    }

                    const plansApprovedListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERMITTING_PLANS_APPROVED.value);
                    if (plansApprovedListFiles != null && plansApprovedListFiles != undefined) {

                        plansApprovedListFiles.forEach(itemFile => {

                            this.plansApprovedFiles.push({
                                id: itemFile.id,
                                file: new File([], itemFile.fileName),
                                fileName: itemFile.fileNameShort,
                                fileType: itemFile.fileType,
                                deleted: false,
                            });
                        })
                    }

                    const recordedNocListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.RECORDED_NOC.value);
                    if (recordedNocListFiles != null && recordedNocListFiles != undefined) {

                        recordedNocListFiles.forEach(itemFile => {

                            this.recordedNocFiles.push({
                                id: itemFile.id,
                                file: new File([], itemFile.fileName),
                                fileName: itemFile.fileNameShort,
                                fileType: itemFile.fileType,
                                deleted: false,
                            });
                        })
                    }

                    const signedAndSealedPlansListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SIGNED_AND_SEALED_PLANS.value);
                    if (signedAndSealedPlansListFiles != null && signedAndSealedPlansListFiles != undefined) {

                        signedAndSealedPlansListFiles.forEach(itemFile => {

                            this.signedAndSealedPlansFiles.push({
                                id: itemFile.id,
                                file: new File([], itemFile.fileName),
                                fileName: itemFile.fileNameShort,
                                fileType: itemFile.fileType,
                                deleted: false,
                            });
                        })
                    }

                    const approvedPermitListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.APPROVED_PERMIT.value);
                    if (approvedPermitListFiles != null && approvedPermitListFiles != undefined) {

                        approvedPermitListFiles.forEach(itemFile => {

                            this.approvedPermitFiles.push({
                                id: itemFile.id,
                                file: new File([], itemFile.fileName),
                                fileName: itemFile.fileNameShort,
                                fileType: itemFile.fileType,
                                deleted: false,
                            });
                        })
                    }

                    const supportingDocumentsListFiles = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SUPPORTING_DOCUMENTS.value);
                    if (supportingDocumentsListFiles != null && supportingDocumentsListFiles != undefined) {

                        supportingDocumentsListFiles.forEach(itemFile => {

                            this.supportingDocumentsFiles.push({
                                id: itemFile.id,
                                file: new File([], itemFile.fileName),
                                fileName: itemFile.fileNameShort,
                                fileType: itemFile.fileType,
                                deleted: false,
                            });
                        })
                    }

                    //SPECS
                    const specsFile = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.PERMITTING_SPECS.value)[0];
                    if (specsFile != null && specsFile != undefined) {
                        this.specsFile.id = specsFile.id;
                        this.specsFile.fileName = specsFile.fileName;
                        this.specsFile.fileType = specsFile.fileType;
                    }

                    listAttachments = await this.$store.dispatch("attachmentModule/GetAll", { typeAttachment: TYPE_ATTACHMENT.PROJECT.value, idKey: this.id });

                    //SURVEY
                    const itemSessionDetailSurveyType = listAttachments.filter(atc => atc.typeDocument == TYPE_DOCUMENT.SURVEY.value)[0];
                    if (itemSessionDetailSurveyType != null && itemSessionDetailSurveyType != undefined) {
                        this.surveyFile.id = itemSessionDetailSurveyType.id;
                        this.surveyFile.fileName = itemSessionDetailSurveyType.fileName;
                        this.surveyFile.fileType = itemSessionDetailSurveyType.fileType;
                    }
                }
            },
            
            async confirmFileDelete(listFile, itemFileToDelete) {

                this.listFileDelete = listFile;
                this.itemFileToDelete = itemFileToDelete;

                this.dialog = {
                    show: true,
                    headerText: 'Confirmation',
                    bodyText: `You will DELETE this file [${itemFileToDelete.fileName}], confirm your decision?`,
                    methodCancelOrDeny: () => {},
                    methodConfirm: this.deleteFile,
                    dismissDialog: true,
                    params: 0
                };
            },

            async deleteFile() {

                this.itemFileToDelete.deleted = true;
            },

            async cancel() {
                this.$router.push({ path: "/permitting/pipeline" });
            },

            async uploadFiles(id, idKey, file, typeDocument, deleted = false) {

                let attachmentRequest = {
                    id: id,
                    idKey: idKey,
                    file: file ? file : new Blob(),
                    fileName: file ? file.name : "",
                    fileType: file ? file.type : "",
                    fileSize: file ? file.size : 0,
                    deleted: deleted ? deleted : false,
                    typeAttachment: TYPE_ATTACHMENT.PERMITTING.value,
                    typeDocument: typeDocument,
                }

                if (attachmentRequest.fileName != null && attachmentRequest.fileName != undefined) {
                    let attachmentFileRequest = new FormData();

                    attachmentFileRequest.append("attachmentRequest", JSON.stringify(attachmentRequest));
                    attachmentFileRequest.append("attachmentFile", attachmentRequest.file, attachmentRequest.fileName);

                    const result = await this.$store.dispatch("attachmentModule/CreateUpdate", { 
                        attachmentFileRequest, 
                        id: 0
                    });

                    return result;
                }
                else {
                    return {
                        success: true
                    };
                }
            },

            async saveFiles() {

                let allResult = [];

                this.permitCardFiles.forEach(async itemFile => {

                    const result = await this.uploadFiles(itemFile.id, this.id, itemFile.file, TYPE_DOCUMENT.PERMITTING_PERMIT_CARD.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PERMITTING_PERMIT_CARD.description,
                            message: result.message
                        })
                    }
                })

                this.plansApprovedFiles.forEach(async itemFile => {

                    const result = await this.uploadFiles(itemFile.id, this.id, itemFile.file, TYPE_DOCUMENT.PERMITTING_PLANS_APPROVED.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.PERMITTING_PLANS_APPROVED.description,
                            message: result.message
                        })
                    }
                })

                this.recordedNocFiles.forEach(async itemFile => {

                    const result = await this.uploadFiles(itemFile.id, this.id, itemFile.file, TYPE_DOCUMENT.RECORDED_NOC.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.RECORDED_NOC.description,
                            message: result.message
                        })
                    }
                })

                this.signedAndSealedPlansFiles.forEach(async itemFile => {

                    const result = await this.uploadFiles(itemFile.id, this.id, itemFile.file, TYPE_DOCUMENT.SIGNED_AND_SEALED_PLANS.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.SIGNED_AND_SEALED_PLANS.description,
                            message: result.message
                        })
                    }
                })

                this.approvedPermitFiles.forEach(async itemFile => {

                    const result = await this.uploadFiles(itemFile.id, this.id, itemFile.file, TYPE_DOCUMENT.APPROVED_PERMIT.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.APPROVED_PERMIT.description,
                            message: result.message
                        })
                    }
                })

                this.supportingDocumentsFiles.forEach(async itemFile => {

                    const result = await this.uploadFiles(itemFile.id, this.id, itemFile.file, TYPE_DOCUMENT.SUPPORTING_DOCUMENTS.value, itemFile.deleted);

                    if (result.success == false) {
                        allResult.push({
                            typeDocument: TYPE_DOCUMENT.SUPPORTING_DOCUMENTS.description,
                            message: result.message
                        })
                    }
                })

                if (allResult.length > 0) {
                    let message = ""

                    await allResult.forEach(itemResult => {
                        message += `The [${itemResult.typeDocument}] reported the error: [${itemResult.message}] \n`;
                    })

                    this.showToast("error", "Warning!", message);

                    return false;
                }
                else {
                    return true;
                }
            },

            async validateCheckItems() {

                let result = {
                    success: true
                };

                if (this.permitCardFiles == null || this.permitCardFiles == undefined || this.permitCardFiles.length <= 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must add Permit Card file.`);
                }
                else if (this.plansApprovedFiles == null || this.plansApprovedFiles == undefined || this.plansApprovedFiles.length <= 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must add Plans Approved file.`);
                }
                else if (this.recordedNocFiles == null || this.recordedNocFiles == undefined || this.recordedNocFiles.length <= 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must add Recorded Noc file.`);
                }
                else if (this.signedAndSealedPlansFiles == null || this.signedAndSealedPlansFiles == undefined || this.signedAndSealedPlansFiles.length <= 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must add Signed and Sealed Plans with city approval file.`);
                }
                else if (this.approvedPermitFiles == null || this.approvedPermitFiles == undefined || this.approvedPermitFiles.length <= 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must add Approved Permit file.`);
                }
                else if (this.surveyStatus == null || this.surveyStatus == undefined || this.surveyStatus == 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must check and approve survey document.`);
                }
                else if (this.specsStatus == null || this.specsStatus == undefined || this.specsStatus == 0) {
                    result.success = false;
                    this.showToast("error", "Warning!", `You must check and approve specs document.`);
                }

                return result;
            },

            async validateAndFinish() {

                let result = await this.validateCheckItems();

                if (result.success)
                {
                    let message = "You will finish UPLOAD DOCUMENTS and go to Construction Phase, confirm your decision?";

                    this.dialog = {
                        show: true,
                        headerText: 'Confirmation',
                        bodyText: message,
                        methodConfirm: () => { this.save(true); },
                        params: 0
                    };
                }
            },

            async save(finishProcess) {

                this.showLoading();

                if (await this.saveFiles()) {

                    this.showToast("success", "Success!", "Documents uploaded successfully!");

                    if (finishProcess == true) {

                        let updateStatusRequest = {
                            id: this.id,
                            status: this.PROJECT_STATUS.CONSTRUCTION_PREDIG_INCOMING_PROJECTS.id
                        };

                        await this.$store.dispatch("serviceModule/UpdateProjectStatus", updateStatusRequest);
                    }

                    this.cancel();
                }
                this.hideLoading();
            },
        },

        async created() {

            this.showLoading();

            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>
    .v-btn {
        height: 45px !important;
        width: 150px;
    }

    .imageFileName {
        font-size: 10px; 
        margin-left: 5px;
    }
    
    .v-file-input > .v-input__control {
        height: 55px !important;
    }

    .cardDownload {
        text-align: center;
    }
</style>